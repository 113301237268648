body {
  /* background-image: url('./assets//Ellipse\ 20.png')  ;
  background-repeat: no-repeat;
  background-attachment: fixed;
  
  background-size: 10rem; */
  background-position: center;
  background-color: white;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.centered {
  position: fixed;
  top: 50%;
  left: 65%;
  /* bring your own prefixes */
  transform: translate(-50%, -50%);
}

.centeredmobile {
  position: fixed;
  top: 50%;
  left: 50%;
  /* bring your own prefixes */
  transform: translate(-50%, -50%);
}